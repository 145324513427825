<template>
  <div class="filterSearchComponents">
    <div class="filters">
      <span class="screen">{{ $t("companyScreen") }}：</span>
      <span @click="clearFilter('city')" v-if="filters.city" class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-popover
        placement="right-start"
        width="280"
        trigger="click"
        v-model="popoverShow"
      >
        <div class="cityDiv">
          <searchCity
            @cityChange="cityListClick"
            ref="searchCity"
            :countryId="country_id"
          ></searchCity>
        </div>
        <span class="el-dropdown-link cityTitle" slot="reference">
          <span v-if="filters.city">
            {{ filters.city | getCityNameByCityId(LOCALE) | textFormat }}</span
          >
          <span v-else>{{ $t("city1") }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-popover>

      <!-- <el-dropdown
        trigger="click"
        placement="bottom"
        @command="cityListClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.city">
            {{ filters.city | getCityNameByCityId(LOCALE) | textFormat }}</span
          >
          <span v-else>城市</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
              v-for="item in allCountryCity"
              :key="item.id"
              :command="item"
          >{{
              item.name_en | priorFormat(item.name_zh, LOCALE)
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-else>
          <el-dropdown-item
            v-for="item in cityList"
            :key="item.id"
            :command="item"
            >{{
              item.name_en | priorFormat(item.name_zh, LOCALE)
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
      <span @click="clearFilter('credit')" v-if="filters.credit" class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="creditClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.credit">{{
            filters.credit | creditClassFormat(LOCALE)
          }}</span>
          <span v-else>{{ $t("creditRating") }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in creditClass"
            :key="item.value"
            :command="item"
            >{{
              item.label_en | priorFormat(item.label_zh, LOCALE)
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <span
        @click="clearFilter('year')"
        v-if="filters.year && yearShow"
        class="cursor"
        ><i class="el-icon-close"></i
      ></span> -->
      <el-dropdown
        v-if="yearShow"
        trigger="click"
        placement="bottom-start"
        @command="yearListClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.year">{{
            filters.year | yearListFormat(LOCALE)
          }}</span>
          <span v-else>{{ $t("years") }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in yearList"
            :key="item.value"
            :command="item"
            >{{
              item.label_en | priorFormat(item.label_zh, LOCALE)
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <span
        @click="clearFilter('unitType')"
        v-if="filters.unitType && isShow"
        class="cursor"
        ><i class="el-icon-close"></i
      ></span>
      <el-dropdown
        v-if="isShow"
        trigger="click"
        placement="bottom-start"
        @command="unitTypeListClick"
      >
        <span class="el-dropdown-link">
          <span v-if="filters.unitType">{{
            filters.unitType | unitTypeFormat(LOCALE)
          }}</span>
          <span v-else>{{ $t("unitType") }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in unitType"
            :key="item.value"
            :command="item"
            >{{
              item.label_en | priorFormat(item.label_zh, LOCALE)
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <div class="fr clearFilter" @click="clearAll">
        {{ $t("clearFilters") }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
import searchCity from "~hph/components/common/searchCity";
import {
  yearList,
  cityList,
  creditClass,
  unitType,
} from "~hph/utils/enumValue";
export default {
  props: {
    inputPlaceholder: {
      type: String,
      default: "",
    },
    isShow: {
      //单位类型是否显示
      type: Boolean,
      default: true,
    },
    yearShow: {
      // 年份是否显示
      type: Boolean,
      default: false,
    },
  },
  components: { searchCity },
  data() {
    return {
      select: "",
      keyWord: "",
      creditClass,
      cityList,
      unitType,
      yearList,
      filters: {
        credit: "",
        city: "",
        unitType: "",
        year: 8,
      },
      popoverShow: false,
      //全国城市
      allCountryCity: [],
      country_id: 1,
    };
  },
  watch: {
    popoverShow() {
      if (this.popoverShow) {
        this.$refs.searchCity.clearSearchVal();
      }
    },
    // city() {
    // if (this.city) {
    //   this.cityList = [];
    // } else {
    //   this.country_id = 0;
    // }
    // },
  },
  mounted() {
    this.allCountryCity = getCityListByCountryId(1, this.LOCALE);
  },
  methods: {
    creditClick(item) {
      this.filters.credit = item.value;
      this.handleSearch();
    },
    cityListClick(item) {
      //改为传出去id不是name
      // this.filters.city = item.name_zh || item.name_en;
      this.popoverShow = false;
      this.filters.city = item.city_id;
      this.handleSearch();
    },
    unitTypeListClick(item) {
      this.filters.unitType = item.value;
      this.handleSearch();
    },
    yearListClick(item) {
      this.filters.year = item.value;
      this.handleSearch();
    },
    clearFilter(key) {
      this.filters[key] = "";
      this.handleSearch();
    },
    clearAll() {
      this.filters.city = "";
      this.filters.unitType = "";
      this.filters.credit = "";
      this.filters.year = 1;
      this.handleSearch();
    },
    handleSearch() {
      this.$emit("handleSearch", this.filters);
    },
  },
};
</script>

<style scoped lang="less">
.filterSearchComponents {
  height: 60px;
  background: #fff;
  padding: 24px 30px;
  .filters {
    .screen {
      font-size: 14px;
      margin-right: 20px;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-dropdown {
      margin: 0 50px 0 10px;
    }
    .clearFilter {
      color: #999999;
      cursor: pointer;
    }
  }
}
.cityTitle {
  margin-right: 40px;
}
.cityDiv {
  width: 250px;
  height: 400px;
  border: 1px solid #ccc;
  padding: 0 5px;
  .title {
    width: 100%;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .havaContent {
    height: 300px;
    overflow: auto;
    p {
      font-size: 12px;
      line-height: 28px;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      &:hover {
        background: #f2f2f2;
      }
    }
  }
}
</style>
